<template>
    <v-container fluid>
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <!-- <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_from"
                                            label="Start Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal_to"
                                        v-model="modal_to"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_to"
                                            label="End Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_to"
                                            no-title
                                            @input="modal_to = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col> -->
                                <v-col cols="12" xs="12" sm="5" md="2">
                                 <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                        </template>
                                    <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                        </v-menu>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete  
                                        prepend-inner-icon="mdi-apple-finder"
                                        solo
                                        clearable 
                                        v-model="vendor" 
                                        :items="vendors" 
                                        item-value="vendor_id" 
                                        item-text="vendor_data" 
                                        label="Vendor" 
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-flag"
                                        clearable
                                        dense
                                        solo
                                        v-model="raw"
                                        :items="raws"
                                        item-value="value"
                                        :item-text="item => item.raw"
                                        label="Material Flag"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-list-status"
                                        clearable
                                        dense
                                        solo
                                        v-model="status"
                                        :items="statuses"
                                        item-value="value"
                                        :item-text="item => item.status"
                                        label="Status"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-2 mb-12">
            <v-col cols="12">
                <h6 class="indigo-text m-2">Query Result: </h6>
            </v-col>
            <v-col class="col-12" sm="12" md="6">
                <v-card outlined class="p-0" height="500">
                    <v-card-text>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 475px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12 mb-12" sm="12" md="6">
                <v-card outlined class="p-0">
                    <v-card-text class="p-1">
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="items"
                            class="elevation-1"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="353"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :search="searchItemTrans" 
                            :item-class="tr_datatable"
                            group-by="vendor_name"
                        >    
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                    <div class="d-flex w-100">
                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Item </h6>
                                        <v-spacer></v-spacer>
                                        <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 mr-3 text-white border-12"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel
                                                class="text"
                                                :fetch           = "exportExcel"
                                                :fields="headersColumn"
                                                :before-generate = "startDownload"
                                                :before-finish   = "finishDownload">
                                                Export Excel
                                            </download-excel>
                                        </v-btn>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.dt_po`]="{ item }">
                                {{ (new Date(new Date(item.dt_po) - (new Date()).getTimezoneOffset() *
                        60000)).toISOString().substr(0, 10) }}
                            </template>
                            <template v-slot:[`item.dt_aprv1`]="{ item }">
                                {{ item.dt_aprv1 === '1900-01-01 00:00:00.000' ? '-' : item.dt_aprv1}}
                            </template>
                            <template v-slot:[`item.dt_aprv2`]="{ item }">
                                {{ item.dt_aprv2 === '1900-01-01 00:00:00.000' ? '-' : item.dt_aprv2}}
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.qty)}}
                            </template>
                            <template v-slot:[`item.qty_retur`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.qty_retur)}}
                            </template>
                            <template v-slot:[`item.qty_rcv`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.qty_rcv)}}
                            </template>
                            <template v-slot:[`item.unit_price`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.unit_price)}}
                            </template>
                            <template v-slot:[`item.curr_rate`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.curr_rate)}}
                            </template>
                            <template v-slot:[`item.total_price`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.total_price)}}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Finance Report',
                    disabled: true,
                },
                {
                    text: 'PO Detail',
                    disabled: true,
                }
            ],
            search:'',
            modal: false,
            date_from: '',
            modal_to: false,
            date_to: '',
            departments: [],
            department: '',
            raws: [
                {
                    raw: 'Raw Material Yes',
                    value: 'Y'
                },
                {
                    raw: 'Raw Material No',
                    value: 'N'
                }
            ],
            raw: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            showData: true,
            headers: [
                { text: 'Vendor', value: 'vendor_name', width:120, align: 'left'},
                { text: 'Date PO', value: 'dt_po', width:120, align: 'left', groupable:false},
                { text: 'PO ID', value: 'po_id', width:200, align: 'left', groupable:false },
                { text: 'Status', value: 'stat', width:120, align: 'left', groupable:false},
                // { text: 'Contr ID', value: 'contr_id', width:120, align: 'left', groupable:false},
                // { text: 'PR Item', value: 'pr_item', width:120, align: 'right', groupable:false},
                // { text: 'PR ID', value: 'pr_id', width:120, align: 'left', groupable:false},
                { text: 'PO Item', value: 'po_item', width:100, align: 'right', groupable:false},
                { text: 'Raw Flag', value: 'type', width:120, align: 'left', groupable:false},
                { text: 'Item Descr', value: 'item_descr', width:200, align: 'left', groupable:false},
                { text: 'Description', value: 'descr', width:200, align: 'left', groupable:false},
                { text: 'Qty', value: 'qty', width:120, align: 'right', groupable:false},
                { text: 'Qty Rcv', value: 'qty_rcv', width:120, align: 'right', groupable:false},
                { text: 'Qty Retur', value: 'qty_retur', width:120, align: 'right', groupable:false},
                { text: 'Unit Meas', value: 'unit_meas', width:120, align: 'left', groupable:false},
                { text: 'Currency', value: 'curr_id', width:120, align: 'left', groupable:false},
                { text: 'Curr Rate', value: 'curr_rate', width:120, align: 'right', groupable:false},
                { text: 'Unit Price', value: 'unit_price', width:120, align: 'right', groupable:false},
                { text: 'Total Price', value: 'total_price', width:120, align: 'right', groupable:false},
                
            ],
            headersColumn: {
                'Vendor' : 'vendor_name',
                'Date PO': 'dt_po',
                'PO ID': 'po_id',
                'Status' : 'stat',
                'PO Item' : 'po_item',
                'Raw Flag' : 'type',
                'Item Descr' : 'item_descr',
                'Descr' : 'descr',
                'qty' : 'qty',
                'qty_rcv' : 'qty_rcv',
                'qty_retur' : 'qty_retur',
                'unit_meas' : 'unit_meas',
                'curr_id' : 'curr_id',
                'curr_rate' : 'curr_rate',
                'unit_price' : 'unit_price',
                'total_price' : 'total_price'
            },
            items: [],
            searchItemTrans: '',
            po_items: [],
            vendors: [],
            vendor: ''
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true) 
        await this.getVendor()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        async getVendor(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/sr/vendor`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.vendors = res.data
            });
        },
        clear(){
            this.getChart([], [], "", "", 'container1', '', true);
            this.date_from = ''
            this.date_to = ''
            this.raw = ''
            this.vendor = ''
            this.status = ''
            this.items = []
            this.showData = true
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true) 

            var reqBody = {
                'startPeriod': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'endPeriod': this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'raw_flag': this.raw ? this.raw : '',
                'vendor_id': this.vendor ? this.vendor : '',
                'stat': this.status ? this.status : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/po-analysis', reqBody, false, false, false)
            const respDataChart = await backendApi.fetchCore('/api/v3/sr/po-analysis/chart', reqBody, false, false, false)

            if (respData.status === 200 && respDataChart.status === 200) {
                // console.log(respData.data);
                // console.log(respDataChart.data); 
                this.items = respData.data

                var inputJson = respDataChart.data
                const transformedData = {};

                inputJson.forEach(item => {
                    const type = item.type.trim() == "" ? 'empty' : item.type.toLowerCase();
                    console.log(item.periode.substr(0, 4) + '-' + item.periode.substr(4, 7));
                    if (!transformedData[type]) {
                        transformedData[type] = [];
                    }
                    transformedData[type].push({
                        x: new Date(item.periode.substr(0, 4) + '-' + item.periode.substr(4, 7)),
                        y: parseInt(item.total_price / 1000000),
                        type: item.type
                    });
                });

                var container = 'container1'
                // console.log(transformedData);

                var type_y = (transformedData.y) ? transformedData.y : []
                var type_n = (transformedData.n) ? transformedData.n : []

                await this.getChart(type_y, type_n, "Raw Yes", "Raw N", container, 'stackedColumn', false);

                this.showData = false

                this.$store.dispatch('setOverlay', false)
            }

        },
        async exportExcel(){
            var reqBody = {
                'startPeriod': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'endPeriod': this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'raw_flag': this.raw ? this.raw : '',
                'vendor_id': this.vendor ? this.vendor : '',
                'stat': this.status ? this.status : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/po-analysis', reqBody, false, false, false)

            if (respData.status === 200){
                this.po_items = respData.data
                return this.po_items
            }
        },
        startDownload(){this.$store.dispatch('setOverlay', true)},
        finishDownload(){this.$store.dispatch('setOverlay', false)},
        getChart(dt1, dt2, name1, name2, container, type, destroy = false){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: 'Purchase Chart from PO',
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: 'Total Price',
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisX: {
                    // labelFormatter: function (e) {
                    //     console.log(new Date(e.value - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 7));
                    //     // var month = e.value.substr(0, 4) + '-' + e.value.substr(5, 7)
                    //     // console.log(month);
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
                    // },
                    interval: 1,
                    intervalType: "month"
                },
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Million",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [
                    {
                        type: type,
                        // click: this.showDetail,
                        name: name1,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#BBDEFB",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: type,
                        // click: this.showDetail,
                        name: name2,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#64B5F6",
                        // yValueFormatString: "#,###,,,.##",
                    }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;

            // this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (destroy == true) {
                chart.destroy();
            } else {
                if (x.matches) {

                    for(var i = 0; i < chart.options.data.length; i++){
                        chart.options.data[i].indexLabelFontSize = 6;
                    }
                    chart.render();
                }

                chart.render();
            }
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
    },    
}
</script>